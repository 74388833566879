<template>
  <div class="map" id="maps">
    <div class="map__container _container">
      <h1 class="map__title main-title">{{ $t("nav.map") }}</h1>
      <div class="map__body">
        <TabItem
          :color="'#FF6B6B'"
          @setTab="selectMapTab"
          :selected="selectedMapTab"
          :tabItems="tabLinks"
        >
        </TabItem>
        <TabBlock
          :isSelected="map.title[$lang] === selectedMapTab"
          v-for="(map, i) in maps"
          :key="i"
        >
          <div class="map__content">
            <div class="map__image _ibg" :class="`map__image-${i + 1}`">
              <img :src="'https://admin.hawaii.kz/' + map.img" class="pcView" alt="" />
              <img :src="'https://admin.hawaii.kz/' + map.img_mobile" class="mobView" alt="" />
            </div>
            <!-- <div class="map__info">
              <div class="map__second-title">{{ map.title[$lang] }}</div>
              <div class="map__places" v-html="map.description[$lang]"></div>
            </div> -->
          </div>
        </TabBlock>
        <TabBlock :isSelected="'3D тур' === selectedMapTab">
          <iframe src="https://3d.hawaii.kz/" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </TabBlock>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    TabBlock,
    TabItem,
  },
  data() {
    return {
      selectedMapTab: "",
    };
  },
  mounted() {
    this.selectedMapTab = this.maps[0].title[this.$lang]
  },
  computed: {
    ...mapState(["maps"]),
    tabLinks() {
      let linkArray = [];
      this.maps.forEach((mapItem) => {
        linkArray.push(mapItem.title[this.$lang]);
      });
      linkArray.push("3D тур");
      return linkArray
    },
  },
  methods: {
    ...mapActions(["fetchMaps"]),
    selectMapTab(tab) {
      this.selectedMapTab = tab;
    },
  },
  watch: {
    maps() {      
      this.selectedMapTab = this.maps[0].title[this.$lang]
    }
  },
  created() {
    this.fetchMaps();
  },
};
</script>

<style lang="scss">
.map {
  padding: 65px 0;
  @media (max-width: 991.98px) {
    padding: 45px 0;
  }
  @media (max-width: 479.98px) {
    padding: 32px 0;
  }

  &__title {
    color: #ff6b6b;
    margin: 0 0 151px 0;

    &::after {
      background-image: url("@/assets/icons/title-icon-orange.svg");
    }

    @media (max-width: 991.98px) {
      margin: 0 0 107px 0;
    }
    @media (max-width: 479.98px) {
      margin: 0 0 63px 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 0 107px;
    @media (max-width: 1336.98px) {
      gap: 0 50px;
    }
    @media (max-width: 991.98px) {
      flex-direction: column;
    }
  }

  &__image {
    @media (max-width: 991.98px) {
      margin: 0 auto 50px;
    }
    @media (max-width: 479.98px) {
      margin: 0 auto 24px;
    }

    &-1 {
      img {
        object-fit: contain !important;
      }
      width: 100%;
      height: 80vh;
      @media (max-width: 991.98px) {
        height: 100vh;
        padding-bottom: 58%;
      }
    }

    &-2 {
      img {
        object-fit: contain !important;
      }
      width: 473px;
      height: 646px;
      @media (max-width: 1366.98px) {
        width: 400px;
        height: 548px;
      }
      @media (max-width: 479.98px) {
        width: 80.54%;
        height: auto;
        padding-bottom: 110%;
      }
    }

    &-4 {
      width: 768px;
      height: 636px;
      @media (max-width: 1366.98px) {
        width: 498px;
        height: 410px;
      }
      @media (max-width: 767.98px) {
        width: 398px;
        height: 325px;
      }
      @media (max-width: 479.98px) {
        width: 88%;
        height: auto;
        padding-bottom: 73%;
      }
    }

    &-3 {
      width: 537px;
      height: 646px;
      @media (max-width: 1366.98px) {
        width: 455px;
        height: 546px;
      }
      @media (max-width: 991.98px) {
        width: 370px;
        height: 446px;
      }
      @media (max-width: 479.98px) {
        width: 80.54%;
        height: auto;
        padding-bottom: 97%;
      }
    }
  }

  &__second-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #ff6b6b;
    margin: 0 0 15px 0;
    text-align: center;
    @media (max-width: 991.98px) {
      font-size: 20px;
      margin: 0 0 10px 0;
    }
    @media (max-width: 479.98px) {
      font-size: 16px;
    }
  }

  &__info {
    width: 400px;
    background: #ffffff;
    border: 2px solid #ff6b6b;
    border-radius: 15px;
    padding: 25px 30px;
    @media (max-width: 991.98px) {
      padding: 20px;
    }
    @media (max-width: 479.98px) {
      width: 80%;
      padding: 16px;
      margin: 0 auto;
    }
  }

  &__places {
    font-weight: 400;
    font-size: 20px;
    line-height: 135.72%;
    letter-spacing: 0.02em;
    color: #2c3131;
    p {
      &:not(:last-child) {
        margin: 0 0 5px 0;
      }
    }
    @media (max-width: 991.98px) {
      font-size: 17px;
      p {
        &:not(:last-child) {
          margin: 0 0 3.5px 0;
        }
      }
    }
    @media (max-width: 479.98px) {
      font-size: 14px;
      p {
        &:not(:last-child) {
          margin: 0 0 2px 0;
        }
      }
    }
  }
}
iframe {
  width: 100%;
  height: 700px;
  @media screen and (max-width: 992px) {
    height: 500px;
  }
}

.mobView {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}
.pcView {
  display: block;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
</style>
