<template>
  <div class="tariffs" id="tariffs">
    <div class="tariffs__container _container">
      <h1 class="tariffs__title main-title">{{ $t("tariffs") }}</h1>
      <div class="tariffs__body">
        <!-- {{ tariffs }} -->
        <TabItem
          :tabItems="[
            $t('tabs.aquapark'),
            $t('tabs.spa'),
            $t('tabs.birthday'),
            $t('tabs.club'),
          ]"
          :color="'#209E2F'"
          :selected="selected"
          @setTab="selectTab"
        >
        </TabItem>
        <div class="tariffs__content">
          <TabBlock :isSelected="selected === $t('tabs.aquapark')">
            <div class="tariffs__items">
              <div
                v-for="tariff in tariffs.aquapark"
                class="tariff-item"
                :key="tariff.id"
                v-show="tariff.is_active == '1'"
              >
                <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info">
                      <div class="tariff-item__title">Тариф</div>
                      <div class="tariff-item__group">
                        <div class="tariff-item__name">
                          {{ tariff.ticket_title[$lang] }}
                        </div>
                        <!-- <button class="tariff-item__button tariff-item__button_first base-button"
                          @click.stop="addToCart(tariff)">
                          {{ $t("basket.addCard") }}
                        </button> -->
                        <span
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-if="!activeCart[tariff.id]"
                          >
                            {{ $t("basket.addCard") }}
                          </button>
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-else
                          >
                            {{ $t("deletCart") }}
                          </button>
                        </span>
                        <!-- <router-link to="buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_first base-button">Купить билет
                        </router-link> -->
                      </div>
                      <div
                        class="tariff-item__text"
                        v-if="tariff.short_description[$lang]"
                      >
                        {{ tariff.short_description[$lang] }}
                      </div>
                    </div>
                  </div>
                  <div class="tariff-item__blocks">
                    <div
                      class="tariff-item__block"
                      v-if="tariff.adult_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.adult_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice") }}
                          <span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.adult_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.adult_old_price }} ₸
                        </div>
                      </div>
                    </div>

                    <div
                      class="tariff-item__block"
                      v-if="tariff.child_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.child_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.child_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.child_old_price }} ₸
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="tariff.action == 1" class="loyalty-text">
                    {{ $t("bonusLoyality1") }}
                    <router-link to="/loyalty-program">{{
                      $t("bonusLoyality2")
                    }}</router-link>
                  </p>
                </div>
                <Accordion>
                  <AccordionItem>
                    <template #accordion-header>
                      <div class="tariff-item__buttons">
                        <!-- <button @click.stop="addToCart(tariff)"
                          class="tariff-item__button tariff-item__button_second base-button">{{ $t("basket.addCard") }}
                        </button> -->
                        <span
                          class="tariff-item__button tariff-item__button_second base-button"
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button class="" v-if="!activeCart[tariff.id]">
                            {{ $t("basket.addCard") }}
                          </button>
                          <button class="" v-else>
                            {{ $t("deletCart") }}
                          </button>
                        </span>

                        <button
                          class="tariff-item__more"
                          @click="changeText($event)"
                          data-show="true"
                        >
                          {{ $t("more") }}
                        </button>
                      </div>
                    </template>
                    <template #accordion-content>
                      <div
                        class="tariff-item__description"
                        v-html="tariff.large_description[$lang]"
                      ></div>
                    </template>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.spa')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, i) in tariffs.spa"
                class="tariff-item"
                :key="i"
                v-show="tariff.is_active == '1'"
              >
                <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info">
                      <div class="tariff-item__title">Тариф</div>
                      <div class="tariff-item__group">
                        <div class="tariff-item__name">
                          {{ tariff.ticket_title[$lang] }}
                        </div>
                        <!-- <router-link to="buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_first base-button">Купить билет
                        </router-link> -->
                        <span
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-if="!activeCart[tariff.id]"
                          >
                            {{ $t("basket.addCard") }}
                          </button>
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-else
                          >
                            {{ $t("deletCart") }}
                          </button>
                        </span>
                      </div>

                      <div
                        class="tariff-item__text"
                        v-if="tariff.short_description[$lang]"
                      >
                        {{ tariff.short_description[$lang] }}
                      </div>
                    </div>
                  </div>
                  <div class="tariff-item__blocks">
                    <div
                      class="tariff-item__block"
                      v-if="tariff.adult_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.adult_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.adult_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.adult_old_price }} ₸
                        </div>
                      </div>
                    </div>

                    <div
                      class="tariff-item__block"
                      v-if="tariff.child_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.child_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.child_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.child_old_price }} ₸
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="tariff.action == 1" class="loyalty-text">
                    {{ $t("bonusLoyality1") }}
                    <router-link to="/loyalty-program">{{
                      $t("bonusLoyality2")
                    }}</router-link>
                  </p>
                </div>
                <Accordion>
                  <AccordionItem>
                    <template #accordion-header>
                      <div class="tariff-item__buttons">
                        <!-- <router-link to="" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_second base-button">Купить билет
                        </router-link> -->
                        <span
                          class="tariff-item__button tariff-item__button_second base-button"
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button class="" v-if="!activeCart[tariff.id]">
                            {{ $t("basket.addCard") }}
                          </button>
                          <button class="" v-else>
                            {{ $t("deletCart") }}
                          </button>
                        </span>
                        <button
                          class="tariff-item__more"
                          @click="changeText($event)"
                        >
                          {{ $t("more") }}
                        </button>
                      </div>
                    </template>
                    <template #accordion-content>
                      <div
                        class="tariff-item__description"
                        v-html="tariff.large_description[$lang]"
                      ></div>
                    </template>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.birthday')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, i) in tariffs.birthday"
                class="tariff-item"
                :key="i"
                v-show="tariff.is_active == '1'"
              >
                <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info">
                      <div class="tariff-item__title">Тариф</div>
                      <div class="tariff-item__group">
                        <div class="tariff-item__name">
                          {{ tariff.ticket_title[$lang] }}
                        </div>
                        <!-- <router-link to="buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_first base-button">Купить билет
                        </router-link> -->
                        <a target="_blank" href="https://hawaiibanket.tilda.ws/">
                        <span
                        >
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                          >
                            {{ $t("basket.goToWhatsapp") }}
                          </button>
                        </span></a>
                      </div>

                      <div
                        class="tariff-item__text"
                        v-if="tariff.short_description[$lang]"
                      >
                        {{ tariff.short_description[$lang] }}
                      </div>
                    </div>
                  </div>
                  <div class="tariff-item__blocks">
                    <div
                      class="tariff-item__block"
                      v-if="tariff.adult_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.adult_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.adult_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.adult_old_price }} ₸
                        </div>
                      </div>
                    </div>

                    <div
                      class="tariff-item__block"
                      v-if="tariff.child_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.child_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.child_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.child_old_price }} ₸
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="tariff.action == 1" class="loyalty-text">
                    {{ $t("bonusLoyality1") }}
                    <router-link to="/loyalty-program">{{
                      $t("bonusLoyality2")
                    }}</router-link>
                  </p>
                </div>
                <Accordion>
                  <AccordionItem>
                    <template #accordion-header>
                      <div class="tariff-item__buttons">
                        <!-- <router-link to="/buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_second base-button">Купить билет
                        </router-link> -->
                        <a target="_blank" href="https://hawaiibanket.tilda.ws/">
                          <span
                            class="tariff-item__button tariff-item__button_second base-button"
                          >
                            <button class="">
                              {{ $t("basket.goToWhatsapp") }}
                            </button>
                          </span>
                        </a>
                        <button
                          class="tariff-item__more"
                          @click="changeText($event)"
                        >
                          {{ $t("more") }}
                        </button>
                      </div>
                    </template>
                    <template #accordion-content>
                      <div
                        class="tariff-item__description"
                        v-html="tariff.large_description[$lang]"
                      ></div>
                    </template>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.club')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, i) in tariffs.club_card"
                class="tariff-item"
                :key="i"
                v-show="tariff.is_active == '1'"
              >
                <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info">
                      <div class="tariff-item__title">Тариф</div>
                      <div class="tariff-item__group">
                        <div class="tariff-item__name">
                          {{ tariff.ticket_title[$lang] }}
                        </div>
                        <!-- <router-link to="buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_first base-button">Купить билет
                        </router-link> -->
                        <span
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-if="!activeCart[tariff.id]"
                          >
                            {{ $t("basket.addCard") }}
                          </button>
                          <button
                            class="tariff-item__button tariff-item__button_first base-button"
                            v-else
                          >
                            {{ $t("deletCart") }}
                          </button>
                        </span>
                      </div>

                      <div
                        class="tariff-item__text"
                        v-if="tariff.short_description[$lang]"
                      >
                        {{ tariff.short_description[$lang] }}
                      </div>
                    </div>
                  </div>
                  <div class="tariff-item__blocks">
                    <div
                      class="tariff-item__block"
                      v-if="tariff.adult_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.adult_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.adult_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.adult_old_price }} ₸
                        </div>
                      </div>
                    </div>

                    <div
                      class="tariff-item__block"
                      v-if="tariff.child_actual_price"
                    >
                      <div class="tariff-item__condition">
                        <div class="tariff-item__type">
                          {{ tariff.child_ticket[$lang] }}
                        </div>
                        <div class="tariff-item__age">
                          {{ $t("noSalePrice")
                          }}<span class="tariff-item__line"></span>
                        </div>
                      </div>
                      <div class="tariff-item__prices">
                        <div class="tariff-item__price">
                          {{ tariff.child_actual_price }} ₸
                        </div>
                        <div class="tariff-item__old-price">
                          {{ tariff.child_old_price }} ₸
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="tariff.action == 1" class="loyalty-text">
                    {{ $t("bonusLoyality1") }}
                    <router-link to="/loyalty-program">{{
                      $t("bonusLoyality2")
                    }}</router-link>
                  </p>
                </div>
                <Accordion>
                  <AccordionItem>
                    <template #accordion-header>
                      <div class="tariff-item__buttons">
                        <!-- <router-link to="/buy-ticket" @click="openTariff(tariff.id)"
                          class="tariff-item__button tariff-item__button_second base-button">Купить билет
                        </router-link> -->
                        <span
                          class="tariff-item__button tariff-item__button_second base-button"
                          :class="{ active: activeCart[tariff.id] }"
                          @click.stop="addToCart(tariff)"
                        >
                          <button class="" v-if="!activeCart[tariff.id]">
                            {{ $t("basket.addCard") }}
                          </button>
                          <button class="" v-else>
                            {{ $t("deletCart") }}
                          </button>
                        </span>
                        <button
                          class="tariff-item__more"
                          @click="changeText($event)"
                        >
                          {{ $t("more") }}
                        </button>
                      </div>
                    </template>
                    <template #accordion-content>
                      <div
                        class="tariff-item__description"
                        v-html="tariff.large_description[$lang]"
                      ></div>
                    </template>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </TabBlock>
        </div>
        <ModalComponent :showModal="showModal" @hideModal="hideModal">
          <div class="ticket-modalnew">
            <div class="ticket-modalnew__main">
              <div class="ticket-modalnew__main-button">
                <span
                  :class="{ active: activeCart[this.modalData.id] }"
                  @click.stop="addToCart(this.modalData)"
                >
                  <button
                    class="btn_out_blue"
                    v-if="!activeCart[this.modalData.id]"
                  >
                    Добавить в корзину
                  </button>
                  <button class="btn_out_blue" v-else>Удалить с корзины</button>
                </span>
                <router-link
                  to="buy-ticket"
                  @click="openTariff(this.modalData.id)"
                  class="btn_blue"
                  >Купить билет
                </router-link>
                <!-- <button class="blue-button">Оформить заказ</button> -->
              </div>
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  </div>
</template>

<script>
import TabBlock from "@/components/TabBlock";
import AccordionItem from "@/components/AccordionItem";
import Accordion from "@/components/AccordionComponent";
import TabItem from "@/components/TabItem";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification";
import ModalComponent from "@/components/ModalComponent";

export default {
  components: {
    Accordion,
    AccordionItem,
    TabBlock,
    TabItem,
    ModalComponent,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isActive: "1",
      selected: this.$t("tabs.aquapark"),
      tariffs: [],
      isOpen: false,
      activeCart: [false],
      cartList: null,
      showModal: false,
      modalData: null,
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "getIsAuth",
      isUnAuth: "getUnAuth",
    }),
  },
  methods: {
    ...mapMutations(["SET_TARIFFID"]),
    goToBuy() {
      if (this.isAuth) {
        this.$router.push("/buy-ticket");
      } else {
        this.$router.push("/login");
      }
    },
    changeText(ev) {
      ev.target.textContent === this.$t("more")
        ? (ev.target.innerText = this.$t("hide"))
        : (ev.target.innerText = this.$t("more"));
      // if (ev.getAttribute("data-show") === "true") {
      //   ev.innerText = this.$t("more");
      //   ev.setAttribute("data-show", "false");
      // } else {
      //   ev.innerText = this.$t("hide");
      //   ev.setAttribute("data-show", "true");
      // }
    },
    goToCart() {
      if (this.isAuth) {
        this.$router.push("/basket");
      } else {
        this.$router.push("login");
      }
    },
    selectTab(tab) {
      this.selected = tab;
    },
    openTariff(value, isOpen) {
      this.showModal = isOpen;
      localStorage.setItem("payment", "true");
      this.goToBuy();
      this.SET_TARIFFID(value);
    },
    openTariffOnCart(tariff) {
      localStorage.setItem("payment", "true");
      this.goToCart();
      this.SET_TARIFFID(tariff);
    },
    addToCart(tariff) {
      let product = tariff;
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      if (cartList) {
        if (cartList.length >= 3) {
          if (cartList.find((tar) => tar.id === tariff.id)) {
            this.activeCart[tariff.id] = !this.activeCart[tariff.id];
            this.$store.commit("ADD_TO_CART", { product, count: 1 });
          } else {
            // alert("Вы можете выбрать в корзину максимум 3 тарифа");
            this.toast.warning(`Вы можете выбрать в корзину максимум 3 тарифа`);
          }
        } else {
          this.activeCart[tariff.id] = !this.activeCart[tariff.id];
          this.$store.commit("ADD_TO_CART", { product, count: 1 });
        }
      } else {
        this.activeCart[tariff.id] = !this.activeCart[tariff.id];
        this.$store.commit("ADD_TO_CART", { product, count: 1 });
      }
    },
    inTheCart() {
      if (this.cartList) {
        this.cartList.filter((i) => {
          for (let x = 0; x < 4; x++) {
            let ids = this.tariffs[Object.keys(this.tariffs)[x]];
            ids.forEach((item, idx) => {
              if (i.id === item.id) {
                this.activeCart[item.id] = true;
              }
            });
          }
        });
      }
    },
    hideModal(isOpen) {
      this.showModal = isOpen;
    },
    openModal(tariff, isOpen) {
      // console.log('asdasdasd',tariff);
      this.modalData = tariff;
      this.showModal = !isOpen;
      document.body.style.overflow = "auto";
    },
  },
  created() {
    axios
      .get("https://admin.hawaii.kz/api/V1/ticket")
      .then((response) => ((this.tariffs = response.data), this.inTheCart()))

      .catch((error) => {
        // this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    this.cartList = JSON.parse(localStorage.getItem("cart_products"));
  },
  watch: {
    "this.showModal"() {
      if (this.showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style lang="scss">
.btn_blue {
  font-weight: 700;
  // font-size: 20px;
  font-size: calc(15px + 5 * (100vw / 1320));
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #256ed8;
  border: 1px solid #256ed8;
  border-radius: 15px;
  padding: 5px 10px;
  width: 340px;

  @media (min-width: 991.9px) {
    padding: 18px 24px;
  }
}

.btn_out_blue {
  font-weight: 700;
  // font-size: 20px;
  font-size: calc(15px + 5 * (100vw / 1320));
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #256ed8;
  background: #ffffff;
  border: 1px solid #256ed8;
  border-radius: 15px;
  padding: 5px 10px;
  width: 340px;

  @media (min-width: 991.9px) {
    padding: 18px 24px;
  }
}

.ticket-modalnew {
  position: relative;
  width: 90%;

  &__main {
    &-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      gap: 10px;

      @media (max-width: 991.9px) {
        flex-direction: column;
      }

      span {
        button {
          max-width: 340px !important;
          min-width: 340px !important;
        }
      }

      a {
        max-width: 340px !important;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.loyalty-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  // font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #209e2f;
  margin-bottom: 32px;

  a {
    color: #ff6b6b;
    text-decoration: underline !important;
  }
}

.tariffs {
  padding: 65px 0;
  font-family: "Nunito";

  @media (max-width: 767.98px) {
    padding: 32px 0;
  }

  &__title {
    color: #209e2f;
    margin: 0 0 87.38px;

    &::after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 767.98px) {
      margin: 0 0 63px;
    }
  }

  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px 70px;

    @media (max-width: 1366.98px) {
      gap: 40px;
    }

    @media (max-width: 767.98px) {
      justify-content: center;
      gap: 23px;
    }
  }
}

.tariff-item {
  flex: 0 1 47%;
  padding: 52px 52px 28px;
  letter-spacing: 0.02em;
  background: #ffffff;
  border: 3px solid #46c555;
  border-radius: 60px;
  display: flex;
  flex-direction: column;

  .accordion__item {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 1366.98px) {
    border-width: 2px;
    border-radius: 30px;
    padding: 20px 15px;
  }

  @media (max-width: 767.98px) {
    flex: 0 1 70%;
    padding: 24px 16px;
    border-width: 2px;
  }

  @media (max-width: 479.98px) {
    flex: 0 1 100%;
  }

  @media (min-width: 375px) {
    min-width: 343px;
  }

  :last-child {
    .tariffs-aquapark__price {
      margin: 0;
    }
  }

  &__content {
    flex: 1 0 auto;
  }

  .accordion {
    flex: 0 0 auto;
  }

  &__group {
    @media (min-width: 1366.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      margin: 0 0 20px 0;
      gap: 0 20px;
    }

    text-align: center;
    margin: 0 0 16px 0;
  }

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.888%;
    color: #a9a9a9;

    @media (max-width: 1366.98px) {
      font-size: 16px;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 34px;
    line-height: 136.111%;
    color: #ff6b6b;

    @media (max-width: 1366.98px) {
      font-size: 25px;
      margin: 0 0 12px 0;
    }

    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    color: #626262;
    text-align: left;

    @media (max-width: 1366.98px) {
      font-size: 18px;
    }

    @media (max-width: 767.98px) {
      font-size: 15px;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 23px 0;
    position: relative;
    overflow: hidden;

    @media (max-width: 767.98px) {
      margin: 0 0 16px 0;
    }
  }

  &__type {
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    color: #2c3131;

    @media (max-width: 1366.98px) {
      font-size: 18px;
    }

    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }

  &__age {
    font-weight: 400;
    font-size: 16px;
    line-height: 137.5%;
    color: #a9a9a9;

    @media (max-width: 1366.98px) {
      font-size: 15px;
    }

    @media (max-width: 767.98px) {
      font-size: 13px;
    }
  }

  &__line {
    position: absolute;
    display: inline-block;
    width: 100%;
    bottom: 7px;
    height: 1px;
    border-bottom: 1px dashed #a2a6bb;
  }

  &__prices {
    z-index: 2;
    position: relative;
    background: #ffffff;
  }

  &__price {
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    color: #00bed6;

    @media (max-width: 1366.98px) {
      font-size: 18px;
    }

    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }

  &__old-price {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.8%;
    text-decoration-line: line-through;
    color: #a9a9a9;

    @media (max-width: 1366.98px) {
      font-size: 18px;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }

  &__more {
    //margin: 0 auto;
    ////width: fit-content;
    //display: block;
    padding: 12px 16px;
    border: 1px solid #209e2f;
    border-radius: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    color: #209e2f;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    position: relative;

    @media (max-width: 1366.98px) {
      padding: 12px 24px;
      font-size: 16px;
      min-width: 156.06px;
    }

    @media (max-width: 767.98px) {
      min-width: 126.55px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }

  &__description {
    padding: 25px 0 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.02em;
    text-align: justify;
    color: #2c3131;

    @media (max-width: $tablet + px) {
      font-size: 16px;
      padding: 12px 0 28px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__buttons {
    margin: 0 auto;
    @media (max-width: $laptop + px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      :not(:last-child) {
        margin: 0 auto 12px auto;
      }
    }
  }

  &__button {
    &_second {
      @media (min-width: 1366.98px) {
        display: none !important;
      }
    }

    &_first {
      @media (max-width: 1366.98px) {
        display: none !important;
      }
    }
  }
}
</style>
