<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__top">
        <div class="footer__info">
          <router-link to="/" class="footer__logo"
            ><img src="../assets/images/logo/logo1.png" alt=""
          /></router-link>
          <div
            class="footer__description"
            v-html="$t('main.introTitle') + ' - ' + $t('main.introDesc')"
          ></div>
          <div class="footer__socials">
            <a
              href="https://instagram.com/miami_hawaii_aquapark?igshid=YmMyMTA2M2Y="
              class="footer__social"
              ><img src="@/assets/icons/social/instagram.svg" alt=""
            /></a>
            <a
              href="https://www.tiktok.com/@miami_hawaii_aqua?_t=8U92xVqOwFS&_r=1"
              class="footer__social"
              ><img src="@/assets/icons/social/tiktok.svg" alt=""
            /></a>
            <a href="https://t.me/miami_hawaii_aquapark" class="footer__social"
              ><img src="@/assets/icons/social/telega.png" alt=""
            /></a>
            <a
              href="https://youtube.com/channel/UCv_z1W5ec8-j1ZOI4GjWIkg"
              class="footer__social"
              ><img src="@/assets/icons/social/youtube.svg" alt=""
            /></a>
          </div>
        </div>
        <div class="footer__columns">
          <div class="footer__column">
            <ul class="footer__list">
              <li v-for="link in footerLinks" :key="link.id">
                <a @click="scrollToSection(link.route)" class="footer__title">{{
                  link.name
                }}</a>
              </li>
            </ul>
          </div>

          <div class="footer__column">
            <ul class="footer__list">
              <li>
                <h3 class="footer__title">{{ $t("complex") }}</h3>
              </li>
              <li v-for="linkPage in footer.about" :key="linkPage.id">
                <router-link
                  :to="{ name: 'about', params: { id: linkPage.slug } }"
                  class="footer__link"
                  >{{ linkPage.title[$lang] }}</router-link
                >
              </li>
              <li>
                <router-link to="/news" class="footer__link">Блог</router-link>
              </li>
            </ul>
          </div>

          <div class="footer__column">
            <ul
              class="footer__list"
              v-for="staywus in footer.staywus"
              :key="staywus.id"
            >
              <li>
                <h3 class="footer__title">{{ $t("stayWithUs") }}</h3>
              </li>
              <li>
                <div class="footer__link">
                  <span><img src="@/assets/icons/email.svg" alt="" /></span
                  >{{ staywus.service }}
                </div>
              </li>
              <li>
                <div class="footer__link">
                  <span><img src="@/assets/icons/email.svg" alt="" /></span
                  >{{ staywus.market }}
                </div>
              </li>
              <li>
                <a :href="`tel:${footer.staywus[0].num1}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(staywus.num1) }}
                </a>
              </li>
              <li>
                <a :href="`tel:${footer.staywus[0].num2}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(staywus.num2) }}
                </a>
              </li>
            </ul>
          </div>
          <div class="footer__column _last">
            <ul
              class="footer-list"
              v-for="contact in footer.contacts"
              :key="contact.id"
            >
              <li>
                <h3 class="footer__title">{{ $t("feedback") }}</h3>
                <a :href="`tel:${contact.rns}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(contact.rns) }}
                </a>
              </li>
              <li>
                <h3 class="footer__title">{{ $t("spaCenter") }}</h3>
                <a :href="`tel:${contact.spa}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(contact.spa) }}
                </a>
              </li>
              <li>
                <h3 class="footer__title">{{ $t("humanResources") }}</h3>
                <a :href="`tel:${contact.hr1}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(contact.hr1) }}
                </a>
                <a :href="`tel:${contact.hr2}`" class="footer__link">
                  <span
                    ><img src="@/assets/icons/phone-white.svg" alt="" /></span
                  >{{ formatPhoneNumber(contact.hr2) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div class="footer__bottom">
        <div
          class="footer__socials footer__socials_second"
          data-da=".footer__bottom,767,0"
        >
          <a
            href="https://instagram.com/miami_hawaii_aquapark?igshid=YmMyMTA2M2Y="
            class="footer__social"
            ><img src="@/assets/icons/social/instagram.svg" alt=""
          /></a>
          <a
            href="https://www.tiktok.com/@miami_hawaii_aqua?_t=8U92xVqOwFS&_r=1"
            class="footer__social"
            ><img src="@/assets/icons/social/tiktok.svg" alt=""
          /></a>
          <a href="https://t.me/miami_hawaii_aquapark" class="footer__social"
            ><img src="@/assets/icons/social/telega.png" alt=""
          /></a>
          <a
            href="https://youtube.com/channel/UCv_z1W5ec8-j1ZOI4GjWIkg"
            class="footer__social"
            ><img src="@/assets/icons/social/youtube.svg" alt=""
          /></a>
        </div>
        <div class="footer__column _bottom">
          <ul class="footer__list">
            <li v-for="dataproc in footer.oferta" :key="dataproc.id">
              <img :src="require('@/assets/icons/document-text.png')" />
              <router-link
                :to="{ name: 'rule', params: { name: 'oferta' } }"
                class="footer__link"
                >{{ dataproc.title }}
              </router-link>
            </li>
            <li v-for="rule in footer.rules" :key="rule.id">
              <img :src="require('@/assets/icons/document-text.png')" />
              <router-link
                :to="{ name: 'rule', params: { name: 'rules' } }"
                class="footer__link"
                >{{ rule.title }}</router-link
              >
            </li>
            <li v-for="payment in footer.payment" :key="payment.id">
              <img :src="require('@/assets/icons/document-text.png')" />
              <router-link
                :to="{ name: 'rule', params: { name: 'payment' } }"
                class="footer__link"
                >{{ payment.title }}</router-link
              >
            </li>
            <li v-for="dataproc in footer.dataproc" :key="dataproc.id">
              <img :src="require('@/assets/icons/document-text.png')" />
              <router-link
                :to="{ name: 'rule', params: { name: 'dataproc' } }"
                class="footer__link"
                >{{ dataproc.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer__copyright">
          {{ $t("too") }}
          <p class="cooperation-right__offer" v-html="$t('nomad')"></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      footerLinks: [
        { id: 1, name: this.$t("nav.hawaii"), route: "aquapark-info" },
        { id: 2, name: this.$t("nav.spa"), route: "spa" },
        { id: 3, name: this.$t("sales"), route: "stock" },
        { id: 4, name: this.$t("nav.question"), route: "faq" },
      ],
      footerTitles: [
        "corpoative-clients",
        "group-visits",
        "vacancies",
        "location",
      ],
    };
  },
  computed: {
    ...mapState(["footer"]),
  },
  created() {
    this.fetchFooter();
  },
  methods: {
    ...mapActions(["fetchFooter"]),
    ...mapMutations(["SET_ABOUT"]),
    scrollToSection(link) {
      if (this.$route.name != "Home") {
        this.$router.push("/");
      }

      this.$emit("scroll", link);
    },
    openAboutPage(id) {
      this.SET_ABOUT(id);
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return (
          "+" +
          match[1] +
          " (" +
          match[2] +
          ") " +
          match[3] +
          "-" +
          match[4] +
          "-" +
          match[5]
        );
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  height: 1px;
  background: #c9c9c9;
  width: 200vw;
  margin-left: -100vw;
  position: absolute;
  bottom: 80px;
  left: 0vw;
  @media (max-width: 1400.98px) {
    bottom: 90px;
  }
}
.footer {
  padding: 100px 0 24px 0;
  background: #023b78;
  position: relative;
  @media (max-width: 991.98px) {
    padding: 70px 0 20px 0;
  }
  @media (max-width: 767.98px) {
    padding: 32px 0 16px 0;
  }

  &__container {
    max-width: 1660px;
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 767.98px) {
      padding: 0 16px;
    }
  }

  &__top {
    @media (min-width: 1366.98px) {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
      justify-content: space-between;
      gap: 0 30px;
    }
    @media (max-width: 991.98px) {
      margin: 0 0 24px 0;
    }
  }

  &__bottom {
    @media (min-width: 767.98px) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .footer__list {
      padding: 0 29px 0 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 36px;
      li {
        display: flex;
        align-items: center;
        gap: 5px;
        @media (max-width: 1400.98px) {
          a {
            font-size: 14px;
          }
        }
      }
      @media (max-width: 1450px) {
        row-gap: 10px;
      }
    }
  }

  &__columns {
    @media (min-width: 479.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 100px;
    }
    @media (max-width: 1660px) {
      gap: 0 70px;
    }
    @media (max-width: 1366.98px) {
      gap: 0 40px;
    }
    @media (max-width: 767.98px) {
      gap: 0 30px;
      flex-wrap: wrap;
    }
  }

  &__column {
    &:not(:nth-child(2)),
    &:not(:last-child) {
      .footer__title {
        cursor: default;
      }
    }
    @media (max-width: 767.98px) {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__info {
    @media (max-width: 1366.98px) {
      &:first-child {
        display: none;
      }
    }
  }

  &__logo {
    display: block;
    margin: 0 0 24px 0;
    a,
    img {
      width: 40%;
    }
  }

  &__description {
    max-width: 260px;
    font-weight: 600;
    font-size: 18px;
    line-height: 139%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0 0 32px 0;
  }

  &__socials {
    @media (max-width: 767.98px) {
      margin: 0 0 24px 0;
    }
    &_second {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__social {
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
    &:not(:last-child) {
      margin: 0 16px 0 0;
    }
  }

  &__column {
    &._last {
      @media (max-width: 991.98px) {
        display: none;
      }

      .footer__title {
        font-weight: 400;
        margin: 0 0 16px 0;
      }

      li:not(:last-child) {
        margin: 0 0 24px 0;
      }

      .footer__link {
        margin: 0 0 16px 0;
      }
    }

    &._bottom {
      @media (max-width: 767.98px) {
        margin: 0 0 80px 0;
        .footer__link {
          padding: 0;
        }
      }
    }
  }

  &__list {
    /* li {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    } */
  }

  &__title,
  &__link {
    font-weight: 700;
    font-size: 18px;
    line-height: 139%;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }

  &__title {
    cursor: pointer;
    margin: 0 0 24px 0;
    @media (max-width: 767.98px) {
      margin: 0 0 12px 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: 400;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.8);
    @media (max-width: 479.98px) {
      padding: 0 0 0 16px;
      white-space: normal;
    }

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;

      img {
        vertical-align: middle;
      }
    }
  }

  &__copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 171%;
    min-width: 420px;
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>
