<template>
  <div class="header" id="header">
    <div
      class="header__container"
      ref="headerContainer"
      v-for="footerSection in footer.staywus"
      :key="footerSection.id"
    >
      <div class="header__small small-header">
        <div class="small-header__info">
          <ul class="small-header__list">
            <li>
              <div class="small-header__item">
                <span class="small-header__icon"
                  ><img src="@/assets/icons/map.svg"
                /></span>
                {{ $t("address") }}
              </div>
            </li>
            <li>
              <a :href="`tel:${footerSection.num1}`" class="small-header__item">
                <span class="small-header__icon"
                  ><img src="@/assets/icons/phone.svg"
                /></span>
                {{ formatPhoneNumber(footerSection.num1) }}</a
              >
            </li>
            <li>
              <a :href="`tel:${footerSection.num2}`" class="small-header__item">
                <span class="small-header__icon"
                  ><img src="@/assets/icons/phone.svg" /></span
                >{{ formatPhoneNumber(footerSection.num2) }}</a
              >
            </li>
            <li class="small-header__social">
              <a
                href="https://www.instagram.com/miami_hawaii_aquapark/"
                class="small-header__item"
              >
                <span class="small-header__icon"
                  ><img src="@/assets/icons/instagram.svg"
                /></span>
                {{ $t("insta") }}</a
              >
            </li>
          </ul>
        </div>
        <div class="small-header__actions">
          <div class="small-header__select">
            <button
              class="small-header__lang"
              v-for="entry in languages"
              :key="entry.title"
            >
              <span class="small-header__action-icon _ibg"
                ><img :src="entry.flag"
              /></span>
              <span>{{ entry.title }}</span>
            </button>
          </div>
          <div class="small-header__button">
            <a href="https://san-remo.kz/"><button>San-Remo</button> </a>
          </div>
          <div class="small-header__login" :class="{ _isAuth: isAuth }">
            <select @change="getLang($event)" v-model="langType">
              <option value="kz" :hidden="langType === 'kz'">Қазақша</option>
              <option value="ru" :hidden="langType === 'ru'">Русский</option>
            </select>
            <router-link v-if="!isAuth" @click="login" to="/login">
              <span class="small-header__login_icon"
                ><img src="@/assets/icons/user.svg"
              /></span>
              <span class="small-header__login_text">{{ $t("auth") }}</span>
            </router-link>
            <router-link v-if="isAuth" to="/personal-account">
              <span class="small-header__login_icon"
                ><img src="@/assets/icons/user.svg"
              /></span>
              <span class="small-header__login_account">{{
                $t("personal")
              }}</span>
            </router-link>
            <button class="small-header__logout" v-if="isAuth" @click="logout">
              <img src="../assets/icons/logout.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div class="header__main main-header" :class="{ _active: isOpen }">
        <div
          class="main-header__icon icon-menu"
          ref="burgerMenu"
          @click="openMenu"
          :class="{
            'menu-open': isOpen,
          }"
        >
          <span></span>
        </div>
        <div class="main-header__logo">
          <router-link to="/">
            <img src="../assets/images/logo/logo1.png" alt="" />
          </router-link>
        </div>
        <div class="main-header__login" :class="{ _isAuth: isAuth }">
          <select @change="getLang($event)" v-model="langType">
            <option value="kz" :hidden="langType === 'kz'">KZ</option>
            <option value="ru" :hidden="langType === 'ru'">RU</option>
          </select>
          <div class="main-header__basketMobile">
            <router-link to="/basket"
              ><span v-if="cartLength">{{ cartLength }}</span
              ><img src="@/assets/icons/basket.svg" alt=""
            /></router-link>
          </div>
          <router-link v-if="!isAuth" @click="login" to="/login">
            <span class="main-header__login_icon"
              ><img src="@/assets/icons/user1.png"
            /></span>
            <span class="main-header__login_text">{{ $t("auth") }}</span>
          </router-link>
          <router-link v-if="isAuth" to="/personal-account">
            <span class="main-header__login_icon"
              ><img src="@/assets/icons/user1.png"
            /></span>
            <span class="main-header__login_account">{{ $t("personal") }}</span>
          </router-link>
          <button class="main-header__logout" v-if="isAuth" @click="logout">
            <img src="../assets/icons/logoutt.svg" alt="" />
          </button>
        </div>
        <div
          class="main-header__menu"
          v-click-out-side="hideMenu"
          :class="{ _active: isOpen }"
          ref="headerMenu"
        >
          <ul class="main-header__list">
            <li class="main-header__link">
              <span class="small-header__icon">
                <img src="@/assets/icons/map.svg" /></span
              >{{ $t("address") }}
            </li>
            <li v-for="(link, i) in headerLinks" :key="i">
              <a class="main-header__link" :class="{courselinl: link.name === 'Акции'}">
              <p  @click="scrollToSection(link.id)">{{
                link.name
              }}</p>
            <div class="courselinl-dropdown" v-if="link.name === 'Акции'">
              <div class="courselinl-dropdown-content">
                <router-link to="/certificates">{{ $t('certification') }}</router-link>
              </div>
            </div></a>
            </li>
            <!-- <li> <router-link to="/loyalty-program"  class="main-header__link">
                Программа лояльности
              </router-link></li> -->
            <li class="main-header__link">
              <router-link
                @click="isOpen = false"
                class="main-header__link main-header__link_login"
                to="/login"
                >{{ $t("auth") }}
              </router-link>
            </li>
            <li class="main-header__link main-header__link_phones">
              <a :href="`tel:${footerSection.num1}`" class="main-header__link">
                <span><img src="@/assets/icons/phone.svg" /></span
                >{{ formatPhoneNumber(footerSection.num1) }}</a
              >
              <a :href="`tel:${footerSection.num2}`" class="main-header__link">
                <span><img src="@/assets/icons/phone.svg" /></span
                >{{ formatPhoneNumber(footerSection.num2) }}</a
              >
            </li>
            <li class="main-header__link main-header__link__button">
              <a href="https://san-remo.kz/"><button>San-Remo</button></a>
            </li>
          </ul>
        </div>
        <!-- <router-link
          to=""
          @click="buyTicket"
          class="main-header__button base-button"
          >Купить билет
        </router-link> -->
        <div class="main-header__basket">
          <router-link to="/basket"
            ><span v-if="cartLength">{{ cartLength }}</span
            ><img src="@/assets/icons/basket.svg" alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <ModalComponent class="success-modal" :showModal="showModal1">
            <div v-click-out-side="hideMenu1" class="ticket-refund__modal refund-modal dfgdfg">
              <div class="refund-modal__close" @click="showModal1 = false">
                <img src="../assets/icons/x.svg" alt="" />
              </div>
              <div class="refund-modal__icon">
                <img src="../assets/icons/habar.png" alt="" />
              </div>
              <div class="refund-modal__content asdsad" v-html="$t('kassaMessage1')" />
            </div>
          </ModalComponent> -->
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import axios from "axios";
import ModalComponent from "./ModalComponent.vue";

export default {
    directives: {
        clickOutSide,
    },
    data() {
        return {
            headerLinks: [
                { name: this.$t("nav.hawaii"), id: "aquapark-info" },
                { name: this.$t("nav.spa"), id: "spa" },
                { name: this.$t("sales"), id: "stock" },
                { name: this.$t("nav.question"), id: "faq" },
                { name: this.$t("nav.map"), id: "maps" },
            ],
            selectedLn: {
                name: "Рус",
                value: "ru",
                icon: require("../assets/images/language/ru.png"),
            },
            isOpen: false,
            // showModal1: true,
            isOpenDropdown: false,
            langType: null,
        };
    },
    computed: {
        ...mapState(["footer", "scrollElement"]),
        ...mapGetters({
            isAuth: "getIsAuth",
            isUnAuth: "getUnAuth",
            getUser: "getUser",
            getCarLength: "GET_CART_LENGTH",
        }),
        cartLength() {
            if (this.getCarLength &&
                JSON.parse(localStorage.getItem("cart_products"))) {
                return JSON.parse(localStorage.getItem("cart_products")).length;
            }
            return null;
        },
    },
    mounted() {
        this.langType = localStorage.getItem("lang") || "ru";
    },
    created() {
        this.fetchFooter();
        this.checkAuth();
    },
    methods: {
        ...mapActions(["fetchFooter", "checkAuth", "logout"]),
        ...mapMutations(["SET_SCROLL", "SET_TARIFFID", "UPDATE_LANG"]),
        // hideMenu1() {
        //   this.showModal1 = false
        // },
        getLang(option) {
            this.$i18n.locale = option.target.value;
            this.$lang = option.target.value;
            this.UPDATE_LANG(option.target.value);
            axios.defaults.params = {
                lang: option.target.value,
            };
            location.reload();
        },
        openMenu() {
            this.isOpen = !this.isOpen;
        },
        hideMenu(e) {
            const burger = this.$refs.burgerMenu[0];
            if (!(e.target == burger || burger.contains(event.target))) {
                this.isOpen = false;
            }
        },
        buyTicket() {
            this.checkAuth1();
            this.SET_TARIFFID(null);
        },
        checkAuth1() {
            if (this.isAuth) {
                this.$router.push("/buy-ticket");
            }
            else {
                this.$router.push("/login");
            }
        },
        scrollToSection(link) {
            this.isOpen = false;
            if (this.$route.name != "Home") {
                this.$router.push("/");
            }
            this.$emit("scroll", link);
        },
        formatPhoneNumber(str) {
            let cleaned = ("" + str).replace(/\D/g, "");
            let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                return ("+" +
                    match[1] +
                    " (" +
                    match[2] +
                    ") " +
                    match[3] +
                    "-" +
                    match[4] +
                    "-" +
                    match[5]);
            }
            return null;
        },
        login() {
            localStorage.setItem("payment", "false");
        },
        logout() {
            this.$store.dispatch("logout");
            this.$store.dispatch("checkAuth");
            this.$router.push("/");
        },
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                document.body.classList.add("_lock");
            }
            else {
                if (document.body.classList.contains("_lock")) {
                    document.body.classList.remove("_lock");
                }
            }
        },
    },
    components: { ModalComponent }
};
</script>

<style lang="scss">
.asdsad {
  width: 550px !important;
  
  @media (max-width: $tablet + px) {
    width: auto !important;
    padding: 0 !important;
  }
}
.dfgdfg {

  @media (max-width: 1500px) {
    padding: 46px !important;
  }
  @media (max-width: $tablet + px) {
    padding: 0 !important;
  }
}
body {
  @media (max-width: $tablet + px) {
    &._lock {
      overflow: hidden !important;

      &::before {
        content: "";
        z-index: 4;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: opacity 0.3s ease 0s;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.header {
  &__container {
    max-width: 1660px;
    margin: 0 auto;
    box-sizing: content-box;
    padding: 0 20px;
    @media (max-width: $mobile + px) {
      max-width: none;
      padding: 0 16px;
    }
  }
}

.small-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  @media (max-width: $laptop + px) {
    padding: 20px 0 10px 0;
  }
  @media (max-width: $tablet + px) {
    padding: 15px 0 0 0;
  }
  @media (max-width: $mobile + px) {
    display: none;
  }
  &__button{
    width: 125px;
    margin-right: 20px;
   button{
      width: 100%;
      padding: 10px 0px;
      color: white;
      font-weight: 700;
      border-radius: 30px;
     background: #209e2f;
   }

  }
  &__info {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: center;

    li {
      &:not(:last-child) {
        margin: 0 32px 0 0;
        @media (max-width: $tablet + px) {
          margin: 0 20px 0 0;
        }
      }

      @media (max-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 112.5%;
    letter-spacing: 0.02em;
    color: #626262;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    @media (max-width: $tablet + px) {
      font-size: 14px;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin: 0 8px 0 0;
    line-height: 100%;

    img {
      vertical-align: middle;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__login {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #256ed8;
    select,
    option {
      color: #626262;
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
      letter-spacing: 0.02em;
    }
    select {
      margin-right: 15px;
    }
    a {
      display: flex;
      align-items: center;
    }

    &_icon {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }

      @media (max-width: 1182px) {
        width: 18px;
        height: 18px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &_text {
      margin: 0 0 0 8px;
    }

    &_account {
      margin: 0 0 0 8px;
      @media (max-width: 1182px) {
        display: none;
      }
    }
  }

  &__logout {
    height: 27px;
    border-left: 1px solid #e5e5e5;
    padding: 0 0 0 16px;
    margin: 0 0 0 16px;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 18px;
      height: 20px;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
    }
  }

  &__select,
  &__login {
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    //line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #626262;
    @media (max-width: $mobileSmall+ px) {
      font-size: 14px;
    }
  }

  &__select {
    padding: 0 16px 0 0;
    margin: 0 16px 0 0;
    border-right: 1px solid #e5e5e5;
    @media (max-width: $tablet + px) {
      padding: 0 8px 0 0;
      margin: 0 16px 0 0;
    }
    @media (max-width: $mobile+ px) {
      border: none;
      padding: 0;
      margin: 20px 24px 0 0;
    }
  }

  &__lang {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;

    &:not(:last-child) {
      margin: 0 24px 0 0;
    }

    @media (max-width: 767.98 + px) {
      padding: 5px 8px;
      &_.active,
      &:first-child {
        background: #f4f4f4;
      }
    }
  }

  &__action-icon {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    text-align: center;

    img {
      vertical-align: middle;
    }

    @media (max-width: $tablet + px) {
      margin: 0 8px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 3px 0 0;
    }
  }

  &__social {
    @media (max-width: 1000px) {
      display: none;
    }
  }
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;

  @media (max-width: $laptop + px) {
    justify-content: normal;
  }
  @media (max-width: $tablet + px) {
    justify-content: space-between;
  }
  @media (max-width: $mobile + px) {
    padding: 16px 0;
  }

  &__menu {
    z-index: 21;
    flex: 0 1 973px;
    background: #ffffff;
    @media (max-width: $tablet + px) {
      position: fixed;
      top: 0;
      right: 0;
      width: 65%;
      height: 100%;
      padding: 20px 0 0 24px;
      transition: all 0.3s ease-in;
      transform: translateX(100%);
      &._active {
        transform: translateX(0);
      }
    }
  }

  &__login {
    display: none;
    @media (max-width: $mobile + px) {
      select,
      option {
        color: #626262;
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        color: #256ed8;
        letter-spacing: 0.02em;
      }
      select {
        margin-right: 15px;
        color: #256ed8;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      color: #256ed8;

      a {
        display: flex;
        align-items: center;
      }

      &_icon {
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
        }

        @media (max-width: 1182px) {
          width: 14px;
          height: 18px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      &_text {
        margin: 0 0 0 8px;
        color: #256ed8;
        font-weight: 600;
        @media (max-width: $mobileSmall + px) {
          font-size: 14px;
        }
      }

      &_account {
        margin: 0 0 0 8px;
        @media (max-width: 1182px) {
          display: none;
        }
      }
    }
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    border-left: 1px solid #e5e5e5;
    padding: 0 0 0 16px;
    margin: 0 0 0 16px;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 17px;
      height: 20px;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px; //yezlzhas
    @media (max-width: $tablet + px) {
      display: block;
      li,
      .small-header__item {
        padding: 12px 0;
        border-bottom: 0.3px solid rgba(169, 169, 169, 0.6);
      }
    }

    li {
      &:first-child {
        @media (min-width: $mobile + px) {
          display: none;
        }
      }
    }
  }

  &__link,
  .small-header__item {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 139%;
    letter-spacing: 0.02em;
    white-space: nowrap; //yelzhas
    color: #256ed8;
    cursor: pointer;
    @media (max-width: $laptop + px) {
      font-size: 16px;
    }
    @media (max-width: $tablet + px) {
      font-size: 14px;
      line-height: 135.71%;
      color: #2c3131;
    }

    &_login {
      @media (min-width: $tablet + px) {
        display: none;
      }
    }
    &__button{
    @media (min-width: $mobile + px) {
      display: none;
    }
    width: 60%;
    button{
        width: 100%;
        padding: 10px 0px;
        color: white;
        font-weight: 700;
        border-radius: 30px;
        background: #209e2f;
    }
    }
    &_phones {
      a {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin: 0 0 012px 0;
        }

        span {
          width: 18px;
          height: 18px;
          margin: 0 8px 0 0;
          line-height: 100%;

          img {
            vertical-align: middle;
          }
        }
      }

      @media (min-width: $mobile + px) {
        display: none;
      }
    }

    &_lang {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__logo {
    @media (min-width: $tablet + px) {
      margin: 0 50px 0 0;
    }

    img,
    a {
      display: block;
      object-fit: contain;
      height: 92px;
      width: 98px;
      @media (max-width: $tablet + px) {
        width: 77px;
        height: 72px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 56px;
        height: 44px;
      }
    }
  }

  &__button {
    @media (max-width: $laptop + px) {
      display: none !important;
    }
    @media (max-width: $tablet + px) {
      display: block !important;
    }
    @media (max-width: $mobile + px) {
      display: none !important;
    }
  }

  &__action-icon {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    text-align: center;

    img {
      vertical-align: middle;
    }

    @media (max-width: $tablet + px) {
      margin: 0 8px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 3px 0 0;
    }
  }
  &__basketMobile {
    @media (max-width: $tablet + px) {
      a {
        position: relative;
        span {
          position: absolute;
          right: 5px;
          top: -8px;
          background-color: #ea6c6c;
          color: #fff;
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 12px;
        }
      }
      img {
        width: 18px;
        margin-right: 15px;
      }
    }
  }
  &__basket {
    a {
      position: relative;
      span {
        position: absolute;
        right: -12px;
        top: -8px;
        background-color: #ea6c6c;
        color: #fff;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
      }
    }
    @media (max-width: $tablet + px) {
      display: none;
    }
  }
}

.icon-menu {
  display: none;
  @media (max-width: $tablet + px) {
    display: block;
    position: relative;
    min-width: 30px;
    min-height: 26px;
    width: 30px;
    height: 26px;
    cursor: pointer;
    z-index: 5;
    span,
    &::before,
    &::after {
      content: "";
      transition: all 0.35s ease-in-out;
      right: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 25px;
      background-color: #256ed8;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    span {
      top: calc(50% - 1px);
    }
  }
  @media (max-width: $mobile + px) {
    width: 20px;
    height: 16px;
    min-width: 20px;
    min-height: 16px;
  }

  &.menu-open {
    span {
      background: #ffffff;
      transform: scale(0);
    }

    &::before {
      background: #ffffff;
      transform: rotate(45deg);
      top: calc(50% - 1px);
    }

    &::after {
      background: #ffffff;
      transform: rotate(-45deg);
      bottom: calc(50% - 1px);
    }
  }
}

.courselinl {
  position: relative;
  &-dropdown {
    font-size: 14px;
    position: absolute;
    top: 100%;
    padding-top: 15px;
    left: 0px;
    display: none;
    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      gap: 12px;
      background: white;
      padding: 12px;
      -webkit-box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
      -moz-box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
      box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
      &:before {
        content: "";
        position: absolute;
        border-bottom: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        -webkit-box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
        -moz-box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
        box-shadow: 0px 0px 40px -10px rgba(34, 60, 80, 0.17);
        top: -5px;
        left: 25px;
        margin-left: -5px;
        margin-top: -5px;
      }
      a:hover {
        color: #1900be;
      }
    }
  }
  &:hover {
    .courselinl-dropdown {
      display: flex;
    }
  }
}
  .disable-hover {
    .courselinl-dropdown {
      display: none !important; /* или другой стиль, который отключает hover */
    }
  }
</style>
