<template>
  <div class="_container">
    <div class="certifications__breadcrumbs main-breadcrumbs">
      <router-link
        @click="$router.go(-1)"
        to=""
        class="main-breadcrumbs__link"
        >{{ $t("mainPage") }}</router-link
      >
      <router-link to="" class="main-breadcrumbs__link">{{
        $t("certification")
      }}</router-link>
    </div>
    <div class="certifications">
        <div class="certifications__elem" :class="{noborder: currentCerts.length - 1 === index}" v-for="(item, index) in currentCerts" :key="'cert' + index">
            <div class="certifications__elem-title">{{ item.title }}</div>
            <div class="certifications__elem-img">
                <img :src="require(`@/assets/images/certss/${item.img}.jpg`)" />
            </div>
            <div class="certifications__elem-text">
                <p>{{ $t("withCertifications") }}</p>
                <div class="certifications__elem-text-fix">
                    <ul>
                        <li v-for="(el, idx) in item.includes" :key="'text' + idx">{{ el }}</li>
                    </ul>
                    <div v-if="item.isShe">
                        <p>Для нее:</p>
                        <ul>
                            <li v-for="(el, idx) in item.isShe
                            " :key="'text' + idx">{{ el }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- withCertifications -->
        </div>
    </div>
    <div class="mubervert">
        <button class="mubervert-arrow" @click="currentPage--" :class="{disb: currentPage === 1}" :disabled="currentPage === 1">
            <img style="rotate: 180deg;" :src="require(`@/assets/icons/vects.svg`)" />
        </button>
        <!-- {{ Math.ceil(certifications.length / 6) }} -->
        <div class="mubervert-elem" @click="currentPage = item" :class="{activepag: item === currentPage}" v-for="(item, index) in Math.ceil(certifications.length / 6)" :key="index">
            {{ item }}
        </div>
        <button class="mubervert-arrow" @click="currentPage++" :class="{disb: currentPage === Math.ceil(certifications.length / 6)}" :disabled="currentPage === Math.ceil(certifications.length / 6)">
            <img :src="require(`@/assets/icons/vects.svg`)" />
        </button>
    </div>
  </div>
</template>
<script>
export default {
    data:() => ({
        currentPage: 1,
        certifications: [
            {
                title: "Сертификат аквапарк на 1 персону",
                img: "cert1",
                includes: [
                    "Посещение аквапарка Hawaii & Miami на полный день",
                    "Два больших волновых бассейна",
                    "Летняя зона или термо бассейн в холодное время года",
                    "Детский интерактив",
                    "Водные горки",
                    "Спа-зона в Hawaii"
                ]
            },
            {
                title: 'Сертификат аквапарк+СПА на 1 персону',
                img: "akspa",
                includes: [
                    "Посещение аквапарка Hawaii & Miami на полный день",
                    "Два больших волновых бассейна",
                    "Летняя зона или термо бассейн в холодное время года",
                    "Детский интерактив",
                    "Водные горки",
                    "Спа-зона в Hawaii"
                ]
            },
            // {
            //     title: 'Султан',
            //     img: "Султан",
            //     includes: [
            //         "Вход в Miami SPA на полный день",
            //         "Большой бассейн с 8 джакузи",
            //         "Бассейн Римские термы",
            //         "7 видов саун: Тянь-Шанская, Ханская, Соляная, Тропическая, Царская баня,Хамам Султан Паша и Султан Ханым"
            //     ]
            // },
            // {
            //     title: 'Сертификат аквапарк на 2 персоны',
            //     img: "cert1",
            //     includes: [
            //         "Посещение аквапарка Hawaii & Miami на полный день",
            //         "Два больших волновых бассейна",
            //         "Летняя зона или термобассейн в холодное время года",
            //         "Детский интерактив",
            //         "Водные горки",
            //         "Спа-зона в Hawaii"
            //     ]
            // },
            // {
            //     title: 'Сертификат аквапарк+СПА на 2 персоны',
            //     img: "akspa",
            //     includes: [
            //         "Посещение аквапарка Hawaii & Miami на полный день",
            //         "Два больших волновых бассейна",
            //         "Летняя зона или термобассейн в холодное время года",
            //         "Детский интерактив",
            //         "Водные горки",
            //         "Спа-зона в Hawaii"
            //     ]
            // },
            {
                title: 'Здоровье',
                img: "Zdoro",
                includes: [
                    "Вход в Miami SPA на полный день",
                    "Большой бассейн с 8 джакузи",
                    "Бассейн Римские термы",
                    "7 видов саун: Тянь-Шанская, Ханская, Соляная, Тропическая, Царская баня,Хамам Султан Паша и Султан Ханым"
                ]
            },
            {
                title: 'SPA-пакет "Body-Line" на 1 персону',
                img: "Боди лайн",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фитобочка",
                    "Антицеллюлитное обертывание",
                    "Антицеллюлитный массаж",
                    "Чайная церемония (чайник чая с травами)"
                ]
            },
            {
                title: 'SPA-пакет "Девичник" на 2 персоны',
                img: "Девичник",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Шоколадное обертывание",
                    "Массаж лица и головы",
                    "Глиняная маска для лица",
                    "Чайная церемония (чайник чая с травами)"
                ]
            },
            {
                title: 'SPA-пакет "Остров здоровья" на 1 персону',
                img: "Остров здоровья",
                includes: [
                    "Посещение аквапарка на полный день",
                    "Фитобочка",
                    "Солевой пилинг",
                    "Классический массаж",
                    "Чайная церемония (чайник чая с травами)"
                ]
            },
            {
                title: 'Spa-пакет "Сияй" на 1 персону',
                img: "Сияй",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Базовый уход для лица",
                    "СПА рук",
                    "Глиняная маска"
                ]
            },
            {
                title: 'SPA-пакет "Зимний" на 1 персону',
                img: "Зимний",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Парение в царской бане с веником",
                    "Апельсиновый пилинг с корицей",
                    "Класический массаж",
                    "Чайная церемония"
                ]
            },
            {
                title: 'SPA пакет “Lady SPA” на 1 персону',
                img: "Леди СПА",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фитобочка и парение веником",
                    "Маска для лица «Сияние»",
                    "Арома-релакс массаж",
                    "Классический пилинг",
                    "Чайная церемония"
                ]
            },
            {
                title: 'Пакет Кокосовый РАЙ на 1 персону',
                img: "Кокосовй рай",
                includes: [
                    "Парение в царской бане с веником",
                    "Классический пилинг",
                    "Кокосовый пилинг",
                    "Кокосовое обёртывание",
                    "Чайная церемония",
                    "Неограниченное нахождение на территории аквапарка и SPA-центра"
                ]
            },
            {
                title: 'Пакет Медовый на 1 персону',
                img: "Медовый",
                includes: [
                    "Парение в царской бане с веником",
                    "Медево-солевой пилинг",
                    "Общий массаж",
                    "Чайная церемония",
                    "Неограниченное нахождение на территории аквапарка и SPA-центра",
                    "Волновые бассейны, 12 видов горок, летний аквапарк, термобассейн под открытым небом (в зимний период), 7 видов бань и саун, джакузи"
                ]
            },
            {
                title: 'SPA пакет «Шоколадная королева» на 1 персону',
                img: "Шоколадная королева",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фитобочка или фишпилинг",
                    "Классический пилинг",
                    "Шоколадное обертывание",
                    "Классический массаж",
                    "Чайная церемония"
                ]
            },
            {
                title: 'SPA-свидание «Для неё» на 1 персону',
                img: "Для неё",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фишпилинг",
                    "Фитобочка",
                    "Скраб-пилинг на выбор (классический, медовый, кокосовый, кофейный, апельсиновый)",
                    "Классический массаж",
                    "Молочный коктейль или чайная церемония"
                ]
            },
            {
                title: 'SPA-пакет "Султан" на 1 персону',
                img: "Султан",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фитобочка",
                    "Общий массаж",
                    "Массаж головы",
                    "Чайная церемония (чайник чая с травами)"
                ]
            },
            {
                title: 'SPA-пакет «Полное очищение» на 1 персону',
                img: "Полное очищение",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Фитобочка",
                    "Кофейный скраб",
                    "Шоколадное обертывание + помывка всего тела",
                    "Скидка 20% на дополнительные услуги массажа и пилинга"
                ]
            },
            {
                title: 'SPA-свидание «Ты и я» на 2 персоны',
                img: "Ты и я",
                includes: [
                    "Посещение аквапарка и SPA на полный день для него",
                    "Парение веником",
                    "Арома-релакс массаж",
                    "Цитрусовый скраб-пилинг",
                    "Маска для лица",
                    "Бокал вина или чайная церемония"
                ],
                isShe: [
                    "Фиш-пилинг",
                    "Арома-релакс массаж",
                    "Ягодный скраб-пилинг",
                    "Маска для лица «Сияние»",
                    "Бокал вина или чайная церемония"
                ]
            },
            {
                title: 'Spa-пакет "Цитрусовая Эйфория" на 2 персоны',
                img: "Цитрусовая эйфория",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Парение в царской бане с веником на двоих",
                    "Лимонно-Апельсиновый пилинг",
                    "Класический арома массаж",
                    "Чайная церемония"
                ]
            },
            {
                title: 'SPA-пакет «Удовольствие» на 4 персоны',
                img: "Удовольствие",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "Общий массаж",
                    "Скидка 20% на дополнительные услуги массажа и пилинга"
                ]
            },
            {
                title: 'SPA-пакет «Счастливая семья» на 4 персоны',
                img: "Счастливая семья",
                includes: [
                    "Посещение аквапарка и SPA на полный день",
                    "2 взрослых, 2 детских",
                    "Пилинг на выбор для 2 взрослых",
                    "Классический массаж для 2 взрослых",
                    "Фишпилинг для 2 детей",
                    "Скидка 20% на дополнительные услуги массажа и пилинга"
                ]
            },
        ]
    }),
    computed: {
        currentCerts() {
            return this.certifications.slice((6 * this.currentPage) - 6, 6 * this.currentPage)
        }
    },
    watch: {
        currentPage() {
            document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        }
    }
};
</script>
<style lang="scss" scoped>
.certifications__breadcrumbs {
    margin-top: 16px;
}
.certifications {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    &__elem {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        column-gap: 32px;
        padding-bottom: 36px;
        border-bottom: 1px solid #00000015;
        &-title {
            width: 100%;
            color: #256ED8;
            font-size: min(max(32px, calc(2rem + ((1vw - 3.3px) * -0.5031))), 24px);
            font-weight: 700;
        }
        &-img {
            min-width: 33%;
            max-width: 33%;
            img {
                width: 100%;
            }
            @media screen and (max-width: 800px) {
                min-width: 100%;
                max-width: 400px !important;
                width: 100%;
            }
        }
        &-text {
            flex-grow: 1;
            max-width: 60%;
            p {
                font-size: min(max(22px, calc(1.375rem + ((1vw - 3.3px) * -0.2516))), 18px);
                font-weight: 700;
                margin-bottom: 16px;
                color: #626262;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin-left: 18px;
                list-style-type: disc !important;
                li {
                    color: #626262;
                    list-style: circle;
                    font-weight: 600;
                    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.3px) * -0.2516))), 16px);
                }
            }
            &-fix {
                display: flex;
                align-items: flex-start;
                gap: 40px;
                div {
                    p {
                        font-size: min(max(20px, calc(1.25rem + ((1vw - 3.3px) * -0.2516))), 16px);
                        font-weight: 600;
                        margin-bottom: 5px;
                    }
                }
                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
    }
    .noborder {
        border: none;
    }
}
.mubervert {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 16px 0 100px 0;
    &-arrow {
        border: 1px solid #626262;
    }
    &-elem {
        color: #626262;
        font-size: 18px;
        &:hover {
            border: 1px solid #626262;
            cursor: pointer;
            color: #626262;
        }
    }
    .activepag {
        border: 1px solid #256ED8;
        color: #256ED8;
    }
    div, button {
        outline: none;
        width: fit-content;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .disb {
        opacity: 0.5;
    }
}
</style>
